import React, { useEffect } from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"
import { graphql } from "gatsby"
import get from "lodash/get"

import RoomMock from "../components/RoomMock"
import MediaThumbnail from "../components/MediaThumbnail"
import RoomContentSection from "../components/RoomContentSection"
import RoomContentScroller from "../components/RoomContentScroller"
import HildingVildingButton from "../components/HildingVildingButton"
import HvCloud from "../components/HvCloud"
import VildingScore from "../components/VildingScore"

import { useModal } from "../hooks/modal"
import { useStore } from "../hooks/store"
import getSlugFromString from "../utils/get-slug-from-string"
import { getGames, gameStructure, getGame, getGameData } from "../games"

const GameSection = styled.section`
  position: relative;
  width: calc(100% - 2.5rem);
  margin: 3.5rem auto 75px;
  padding: 2.1875rem 0.5rem 1rem;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 0.3125rem;

  h2 {
    position: absolute;
    top: -3.9rem;
    left: 0;
    width: 100%;
    text-align: center;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
  }

  ul {
    list-style: none;
    margin: 1rem 0 0;
    padding: 0;
  }

  li {
    display: inline-block;
    width: calc(50% - 1rem);
    margin: 0 0.5rem 1rem;
    vertical-align: top;
  }

  @media (min-width: 488px) {
    li {
      width: 12.5rem;
    }
  }
`

const VildingScoreButton = styled.button`
  display: block;
  margin: 0.5rem auto 0;
  padding: 0;
  border: 0;
  background: none;
  font: 0.75rem/0.875rem ${props => props.theme.headingFont};
  color: black;

  > span:first-child {
    display: inline-block;
    margin-bottom: 0.5rem;
  }
`

const QuizWrapper = styled.ul`
  @media (max-width: 487px) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;

    li {
      width: 34%;
      flex-shrink: 0;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media (min-width: 488px) {
    li {
      width: 9rem;
    }
  }
`

const StyledHvCloud = styled(HvCloud)`
  position: fixed;
  bottom: 1.9375rem;
  right: 4.125rem;
  z-index: 10;
`

export default function RoomTemplate6({
  data: { allDidYouKnowThatYaml, allPrintYaml, printDisclaimer, allVideosYaml, allQuizYaml, allMemoryYaml, ciceronYaml },
  pageContext: { lang, pagePath, translations },
}) {
  const intl = useIntl()
  const isEng = lang !== "sv"
  const { openModal } = useModal()
  const hvVideos = get(allVideosYaml.nodes.find((x) => x._id === "hilding-vilding"), "videos")
  const hv = <HildingVildingButton lang={lang} data={ciceronYaml} />
  const [store, updateStore] = useStore()

  const getGameList = (row) =>
    row.map((id) => {
      const { hide, ...gameProps } = getGameData({ id, lang, formatMessage: intl.formatMessage, quizes: allQuizYaml.nodes, memories: allMemoryYaml.nodes })

      if (hide) {
        return null
      }

      return (
        <li key={id}>
          <MediaThumbnail {...gameProps} imageTransform />
        </li>
      )
    })

  // Update Vilding score in store for games that aren't built with React
  useEffect(() => {
    try {
      const lsStore = JSON.parse(window.localStorage.getItem("narkoswebben/store"))

      if (lsStore) {
        updateStore((store) => {
          getGames().forEach((game) => {
            if (store[game.lsKey] !== lsStore[game.lsKey]) {
              store[game.lsKey] = lsStore[game.lsKey]
            }
          })
        })
      }
    } catch (e) {}
  }, [])

  return (
    <RoomMock
      lang={lang}
      path={pagePath}
      room={6}
      title={intl.formatMessage({ id: "roomHeading6" })}
      shareImage={{
        url: `/assets/share-images/NW_Delning8_HV_${lang === "sv" ? "Sve" : "Eng"}.jpg`,
        width: 1200,
        height: 630,
      }}
      ciceron={hv}
      ciceronData={{ text: ciceronYaml.text }}
      translations={translations}
    >
      <RoomContentSection>
        <RoomContentScroller
          items={[
            <MediaThumbnail
              to={`/${lang}/${getSlugFromString(intl.formatMessage({ id: "paintAndPlay" }))}`}
              title={intl.formatMessage({ id: "paintAndPlay" })}
              background={`/assets/hilding_vilding/HV_Cover_Måla_${isEng ? "Eng" : "Sve"}.png`}
              imageTransform
            />,
            <MediaThumbnail
              to={`/${lang}/${getSlugFromString(intl.formatMessage({ id: "theOperationGame" }))}`}
              title={intl.formatMessage({ id: "theOperationGame" })}
              background={`/assets/hilding_vilding/HV_Cover_Opereras_${isEng ? "Eng" : "Sve"}.png`}
              imageTransform
            />,
          ].concat(hvVideos.map((video) => (
            <MediaThumbnail
              provider={video.provider}
              title={video.title}
              background={video.image}
              onClick={(e) => openModal(e, "video", {
                lang,
                id: video.id,
                title: video.title,
                audioDescription: video.audio_description,
              })}
              imageTransform
            />
          )))}
        />
      </RoomContentSection>
      <RoomContentSection>
        <RoomContentScroller
          items={[
            <MediaThumbnail
              title={intl.formatMessage({ id: "didYouKnowThat" })}
              background={`/assets/hilding_vilding/HV_Cover_Visste-du-att_${isEng ? "Eng" : "Sve"}.png`}
              isRound
              border
              onClick={(e) => openModal(e, "didYouKnowThat", { lang, items: allDidYouKnowThatYaml.nodes })}
              imageTransform
            />,
            <MediaThumbnail
              title={intl.formatMessage({ id: "print" })}
              background={`/assets/hilding_vilding/HV_Cover_SkrivUt_${isEng ? "Eng" : "Sve"}.png`}
              isRound
              border
              onClick={(e) => openModal(e, "print", { lang, items: allPrintYaml.nodes, disclaimer: printDisclaimer })}
              imageTransform
            />,
          ].concat(isEng ? [] : [
            <MediaThumbnail
              title="Narkoswebbsången"
              background="/assets/hilding_vilding/Cover_NW-song_Sve.png"
              isRound
              border
              onClick={(e) => openModal(e, "nwSong")}
              imageTransform
            />,
          ])}
          shape="circle"
        />
      </RoomContentSection>
      <GameSection>
        <h2>
          <VildingScoreButton
            onClick={(e) => openModal(e, "vildingScore", {
              lang,
              memories: allMemoryYaml.nodes,
              quizes: allQuizYaml.nodes,
            })}
          >
            <span>{intl.formatMessage({ id: "yourVildingScore" })}<span className="structural">:</span></span>
            <VildingScore showEdit={true} />
          </VildingScoreButton>
        </h2>
        {gameStructure.map((row) => (
          row[0].startsWith("quiz") ?
            <QuizWrapper key="quiz">{getGameList(row)}</QuizWrapper>
          :
            <ul key={row[0]}>{getGameList(row)}</ul>
        ))}
      </GameSection>
      <StyledHvCloud lang={lang} />
    </RoomMock>
  )
}

export const pageQuery = graphql`
  query($lang: String) {
    allDidYouKnowThatYaml(filter: { lang: { eq: $lang } }, sort: { fields: order }) {
      nodes {
        intro
        text
        image
      }
    }
    allPrintYaml(filter: { lang: { eq: $lang } }, sort: { fields: order }) {
      nodes {
        title
        description
        file
        filesize
        image
      }
    }
    printDisclaimer: textsYaml(lang: { eq: $lang }, _id: { eq: "printModalDisclaimer" }) {
      text {
        html
      }
    }
    allVideosYaml(filter: { lang: { eq: $lang }, page: { eq: "Hilding Vildings rum" } }) {
      nodes {
        _id
        title
        videos {
          id
          provider
          title
          image
          audio_description
        }
      }
    }
    allQuizYaml(filter: { lang: { eq: $lang } }) {
      nodes {
        _id
        title
        thumb
      }
    }
    allMemoryYaml(filter: { lang: { eq: $lang } }) {
      nodes {
        _id
        title
        thumb
      }
    }
    ciceronYaml(lang: { eq: $lang }, _id: { eq: "rum-6" }) {
      text
      sound
    }
  }
`
