import React, { useEffect, useState } from "react"
import styled from "styled-components"
import get from "lodash/get"

import { useModal } from "../hooks/modal"
import { useStore } from "../hooks/store"
import useReducedMotion from "../hooks/reducedMotion"

import hvcorner from "../images/hv-corner.svg"

const Button = styled.button`
  position: fixed;
  bottom: 0;
  left: 0;
  display: block;
  border: 0;
  padding: 0;
  margin: 0;
  background: transparent;

  @keyframes animateIn {
    from {transform: translate(-100%, 100%);}
    to   {transform: translate(0,0);}
  }

  @keyframes animateOut {
    from {transform: translate(0,0);}
    to   {transform: translate(-100%, 100%);}
  }

  img {
    display: block;
    width: auto;
    height: 4.1875rem;
    animation: animateIn 1s forwards ;
  }

  &.moveOut {
    img {
      animation: animateOut 1s forwards ;
    }
  }

  ${props => !props.show && `
    display: none;
  `}
`

export default function HildingVildingButton({ lang, data }) {
  const shouldReduceMotion = useReducedMotion()
  const { openModal, closeModal } = useModal()
  const [store, updateStore] = useStore()
  const [show, setShow] = useState(true)
  const [animateClass, setAnimateClass] = useState(null)

  const handleClose = () => {
    setAnimateClass(null)
    setShow(true)
    closeModal()

    if (!store.hideCiceron.room6) {
      updateStore((store) => {
        store.hideCiceron.room6 = true
      })
    }
  }

  const openHvModal = (e = null) => {
    openModal(e, "hildingVilding", {
      audiofile: data.sound,
      text: data.text,
      onClose: handleClose,
    })
  }

  const handleClick = (e) => {
    setAnimateClass("moveOut")
    setTimeout( (e) => {
      setShow(false)
      openHvModal(e)
    }, shouldReduceMotion || !store.animationsEnabled ? 0 : 1500)
  }

  useEffect(() => {
    if (!store.hideCiceron.room6) {
      setShow(false)
      openHvModal()
    }
  }, [])

  return (
    <Button
      show={show}
      aria-label="Hilding Vilding"
      onClick={handleClick}
      className={animateClass}
    >
      <img src={hvcorner} alt="" role="presentation" />
    </Button>
  )
}
