import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import trackEvent from "../utils/track-event"

import cloud from "../images/HV_Moln.png"
import cloudEng from "../images/HV_Moln_Eng.png"

const Button = styled.button`
  width: 7.375rem;
  height: 4.625rem;
  padding: 0;
  border: 0;
  background: none;

  @keyframes cloud-shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  ${props => props.animate && `
    animation-name: cloud-shake;
    animation-iteration-count: 1;
  `}

  img {
    display: block;
    width: 100%;
    height: auto;
  }
`

const HvCloud = ({ lang, ...props }) => {
  const intl = useIntl()
  const componentIsMounted = useRef(true)
  const [animationDuration, setAnimationDuration] = useState(0)

  const durations = [1.5, 1.5, 0.7, 0.7, 1.5, 0.7, 1.5, 0.7, 0.7, 0.4, 0.4]

  const handleClick = () => {
    if (animationDuration) {
      return
    }
    const randomNumber = Math.floor((Math.random() * 11) + 1);
    let sound = new Audio(`/assets/hilding_vilding/Prutt${randomNumber}.mp3`);
    const props = {
      controls: false,
      loop: false,
      autoPlay: false,
      crossOrigin: "anonymous",
    }
    sound = Object.assign(sound, props)
    const soundLoaded = () => {
      sound.removeEventListener("canplaythrough", soundLoaded, false)
      sound.play()
      setAnimationDuration(durations[randomNumber - 1])
      trackEvent({ category: "Buttons", action: "Button Click", name: "HV Cloud" })
    }
    sound.addEventListener("canplaythrough", soundLoaded, false)
  }

  useEffect(() => {
    if (animationDuration) {
      setTimeout(() => {
        if (componentIsMounted.current) {
          setAnimationDuration(0)
        }
      }, animationDuration * 1000)
    }
  }, [animationDuration])

  useEffect(() => {
    return () => {
      componentIsMounted.current = false
    }
  }, [])

  return (
    <Button
      onClick={handleClick}
      animate={!!animationDuration}
      style={{ animationDuration: `${animationDuration}s` }}
      {...props}
    >
      <img src={lang === "sv" ? cloud : cloudEng} alt={intl.formatMessage({ id: "fartCloud" })} />
    </Button>
  )
}

export default HvCloud
